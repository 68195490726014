import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;
const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export const getRosterAndMerchForSchool = (
  school,
  user,
  setRoster,
  callback
) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "allProductsCollectionsAndMerchRosters?uni=" +
    btoa(school),
    { headers }
  );
};
export const searchRosterForName = (searchTerm, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "searchRosterByName?search=" + searchTerm,
    { headers }
  );
};
export const updateMerchRoster = (merchRoster, user, canDeleteProducts) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BASE_HATTRICK_URL + "products/",
    { ...merchRoster, canDeleteProducts },
    { headers }
  );
};
export const updateMerchRosterTransfer = (user, merchRoster) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "updateMerchRoster",
    merchRoster,
    { headers }
  );
};
export const getRostersWithoutProductMockups = (user, school, sport) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "getMerchRostersMissingProductMockups?uni=" +
    btoa(school) +
    "&sport=" +
    btoa(sport),
    {
      headers,
    }
  );
};
export const getRostersWithoutArtFiles = (
  user,
  selectedSchool,
  selectedSport
) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "getMerchRostersMissingArtFiles?uni=" +
    btoa(selectedSchool) +
    "&sport=" +
    btoa(selectedSport),
    {
      headers,
    }
  );
};
export const getRosterByAuthEmployeeSchool = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getRosterByAuthEmployeeSchool",
    {
      headers,
    }
  );
};
export const getCountRosterWhereNotPostedByUniversity = (user) => {
  console.log("user access token", user.accessToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getCountRosterWhereNotPostedByUniversity",
    {
      headers,
    }
  );
};
export const getUnMatchedRosters = (school, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "getRosterWhereUnmatchedByUniversity?uni=" +
    btoa(school),
    {
      headers,
    }
  );
};
export const getMerchRosterBySchool = (school, user) => {
  //console.log(school, btoa(school))
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getMerchRosterBySchool?uni=" + btoa(school),
    {
      headers,
    }
  );
};
export const matchRosterRecords = (merchRosterUpdateObject, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "matchRoster",
    merchRosterUpdateObject,
    {
      headers,
    }
  );
};
export const getUnmatchedCountForUniversities = (user) => {
  console.log("user access token", user.accessToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(BALLPARK_BACKEND_BASE_URL + "unmatchedRosterCounts", {
    headers,
  });
};
export const getAllSportsBySchool = (school, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getAllSportsBySchool?uni=" + btoa(school),
    {
      headers,
    }
  );
};
export const getAnalyticsOptions = (data, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  console.log('Analytics options', data)
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "getAnalyticsOptions",
    data,
    {
      headers,
    }
  );
};

// Zeroout API call to GrandSlam
export const getZeroOutGS = (user) => {
  const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
  };
  
  return axios.get(
    BASE_HATTRICK_URL + "zeroOut",
    {
      headers
    }
  );
};

export const uploadRosterCSV = (files, meta, user, callback) => {
  const formData = new FormData();
  //console.log(files);
  files.forEach((file) => {
    formData.append("file", file);
  });
  formData.append("meta", JSON.stringify(meta));

  return axios.post(BASE_HATTRICK_URL + "roster/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const getSocialMediaGraphics = (user, school, sport, player) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
    "socialMediaGraphics?player=" +
    player +
    "&sport=" +
    btoa(sport) +
    "&college=" +
    btoa(school),
    config
  );
};

export const getRosterAndCollection = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getMerchRosterWithCollections",
    { headers }
  );
};

export const getTotalLive = (user => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getTotalLive",
    { headers }
  );
})

export const createSocialInternRecord = (school, name, email, password, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BASE_HATTRICK_URL + "socialIntern/appAccount/create",
    { school, name, email, password },
    { headers }
  );
}