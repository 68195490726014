import React, { useEffect, useState, useRef } from 'react';
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import {
    Container,
    TextField,
    Button,
    Box,
    Grid,
    Paper,
    Typography,
    FormControl,
    Autocomplete,
    Chip,
    InputAdornment,
    Switch,
    FormControlLabel,
    Tab,
    Tabs
} from '@mui/material';

// https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ValueUpdateSummaryModal from "./ValueUpdate/ValueUpdateSummaryModal";
import ProductMetadataTable from './ArchiveUpdate/ProductMetadataTable';
import ArchiveSummaryModal from "./ArchiveUpdate/ArchiveSummaryModal";
import {WebSocketProvider} from "../../common/webSocket";
import OperationsTable from "./OperationQueue/OperarionTable";
import ProductFilter from "./ValueUpdate/ProductFilter";
import ProductAuditSummaryModal from "./ProductAudit/ProductAuditSummaryModal";
import VariantsCreation from './VariantsCreation/VariantsCreation';
import {
    fetchFilterOptions,
    fetchFilteredProducts, // value summary
    ValueUpdate,
    ArchiveUpdate,
    VariantsCreationUpdate,
    getTypeOptionSelections,
    DescriptionUpdate,
    startProductAuditOperation,
    startBulkArchiveSummary, fetchVariantsCreationSummary
} from "../../../API/audible";
import CollectionSelection from "./ProductAudit/CollectionSelection";
import VariantsCreationSummaryModal from "./VariantsCreation/VariantsCreationModal";
import BulkOperationModal from "./OperationQueue/BulkOperationModal";

const AudibleComponent = () => {
    // For Price, Cost, and Description
    const [universities, setUniversities] = useState([]); // For Price, Cost, Description Product Selection: user selected
    const [sports, setSports] = useState([]); // For Price, Cost, Description Product Selection: user selected
    const [types, setTypes] = useState([]); // For Price, Cost, Description Product Selection: user selected
    const [UniversityOptions, setUniversityOptions] = useState([]); // For Price, Cost, Description Product Selection: selectable options
    const [SportOptions, setSportOptions] = useState([]); // For Price, Cost, Description Product Selection: selectable options
    const [TypeOptions, setTypeOptions] = useState([]); // For Price, Cost, Description Product Selection: selectable options
    const [filteredProducts, setFilteredProducts] = useState([]); // For Price, Cost, Description Product Selection: Products that returned by the backend based on filters (university..etc)
    const [noProductsFound, setNoProductsFound] = useState(false); // For Price, Cost, Description: used in the modal
    const [specificOptions, setSpecificOptions] = useState({}); // For Price, Cost: option-values are now stored here: {Performance Tee: {M : 18, ...}, ...}
    const [isUniformPrice, setIsUniformPrice] = useState(false);// For Price, Cost. To determine if user wants uniform price for a certain type
    const [uniformPrices, setUniformPrices] = useState({});// For Price, Cost: {Performance Tee: True, ...}
    const [isUniformDescription, setIsUniformDescription] = useState(false); // For Uniform Description across all types
    const [uniformDescription, setUniformDescription] = useState('');
    const [typeOptionsMap, setTypeOptionsMap] = useState({}); // Getting specific options for each type from backend: {Performance Tee: [S, M, L, XL, ...], ...}

    // General
    const [loading, setLoading] = useState(true);
    const [updateType, setUpdateType] = useState('Price'); // Update Type
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // we are locking all the forms and buttons to prevent user from changing state when a bulk operation is being sent out and waiting for backend reply.

    // For Archive
    const [selectedProducts, setSelectedProducts] = useState([]); // For Archive
    const [summaryDetails, setSummaryDetails] = useState({});// (Bad Naming): Backend Retuened archive details are stored here: some products to archive, some to delete and some product metadata to delete
    const [refreshTable, setRefreshTable] = useState(false); // (Bad Naming):  Refresh the product Metadata Table for archive
    const [archiveSKUs, setArchiveSKUs] = useState([]); // SKUs that will be archived.

    // Operation Table
    const [refreshOperations, setRefreshOperations] = useState(false);

    // Product Audit
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [refreshCollectionTable, setRefreshCollectionTable] = useState(false);

    // Variants Creation
    const [productSizeData, setProductSizeData] = useState({}); // To store productID and size-related data
    const [variantsCreationType, setVariantsCreationType] = useState("");
    const [clearState, setClearState] = useState(false);


    const user = useUserContext();
    const notify = useNotification();

    const updateTypes = ["Price", "Cost", "Description", "Archive", "Product Audit", "Variants Creation"];

    const handleTabChange = (event, newValue) => {
        setUpdateType(newValue);
        setSpecificOptions({})
    };

    useEffect(() => {
        if (!user) {
            console.log("No user provided");
            setLoading(false);
            return;
        }
        fetchInitialData();
    }, [user]);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            await handleFetchFilterOptions();
            await fetchTypeOptions();
        } catch (error) {
            console.error("Error fetching initial data:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTypeOptions = () => {
        getTypeOptionSelections(user)
            .then(response => {
                const data = response.data.obj["Type Sizes"];
                setTypeOptionsMap(data);
            })
            .catch(error => {
                console.error("Error fetching type options:", error);
                notify("Failed to fetch type options");
            });
    };

    const handleFetchFilterOptions = () => {
        fetchFilterOptions(user)
            .then(response => {
                const { universities, sports, types } = response.data.obj["Bulk Op Filter Options"];
                setUniversityOptions(universities || []);
                setSportOptions(sports || []);
                setTypeOptions(types || []);
            })
            .catch(error => {
                console.error("Error fetching filter options:", error);
                notify("Failed to fetch filter options");
            });
    };

    const handleSpecificOptionChange = (type, option, value) => {
        setSpecificOptions(prevOptions => ({
            ...prevOptions,
            [type]: {
                ...prevOptions[type],
                [option]: value
            }
        }));
    };

    const handleUniformDescriptionChange = (value) => {
        setUniformDescription(value);
        if (isUniformDescription) {
            setSpecificOptions({})
            types.forEach(type => {
                handleSpecificOptionChange(type, 'description', value);
            });
        }
    };

    const handleArchiveSubmit = (e) => {
        if (updateType === "Archive") {
            if (selectedProducts.length === 0) {
                notify("No products selected for archiving");
                return;
            }

            startBulkArchiveSummary(user, selectedProducts)
                .then(response => {
                    const details = response.data.obj["Details to be Updated"];
                    setSummaryDetails(details);
                    setIsModalOpen(true);
                })
                .catch(error => {
                    console.error("Error starting bulk archive operation:", error);
                    notify("Failed to start bulk archive operation");
                });
        }
    };

    const handleProductAuditSubmit = (e) => {
        if (updateType === "Product Audit") {
            // console.log(selectedCollections)
            if (selectedCollections.length === 0) {
                notify("No products selected for auditing");
                return;
            }

            setIsModalOpen(true);
        }
    };

    const handleProductAuditConfirmQuery = (e, note) => {
        if (updateType === "Product Audit") {
            if (selectedCollections.length === 0) {
                notify("No products selected for auditing");
                return;
            }

            // Filter collections without shopifyCollectionID
            const collectionsWithShopifyID = selectedCollections.filter(collection => collection.ShopifyCollectionId !== null);
            const collectionsWithoutShopifyID = selectedCollections.filter(collection => collection.ShopifyCollectionId === null);

            // Notify user if there are collections without shopifyCollectionID
            if (collectionsWithoutShopifyID.length > 0) {
                notify("Some collections do not have a Shopify Collection ID and will be excluded from the audit.");
            }

            if (collectionsWithShopifyID.length === 0) {
                notify("No collections with valid Shopify Collection ID selected for auditing");
                return;
            }

            const selectedCollectionIDs = collectionsWithShopifyID.map(collection => collection.ShopifyCollectionId.toString());
            const selectedCollectionDetails = collectionsWithShopifyID.map(
                collection => `${collection.FullName} (${collection.University} ${collection.Sport})`
            );

            const requestBody = {
                InputFilters: `Auditing On: ${selectedCollectionDetails}`,
                userName: `${user.displayName}: ${user.email}`,
                userNotes: note,
                collectionIDs: selectedCollectionIDs
            };

            startProductAuditOperation(user, requestBody)
                .then(response => {
                    const details = response.data.obj["Details to be Updated"];
                    setSummaryDetails(details);
                    setIsModalOpen(false);
                })
                .catch(error => {
                    console.error("Error starting product audit operation:", error);
                    notify("Failed to start product audit operation");
                });
        }
    };

    // Handle Submit(Product Filter to Backend) for Price and Cost and Description
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if ((updateType === "Price" || updateType === "Cost") && (!types || !types.length)) {
            notify("Type is required for Price or Cost updates");
            setIsSubmitting(false);
            return;
        }

        // Description have no specific options
        if (updateType === "Price" || updateType === "Cost") {
            // Check if all specific option fields are filled
            let allFieldsFilled = true;
            Object.values(specificOptions).forEach(options => {
                Object.values(options).forEach(value => {
                    if (!value) {
                        allFieldsFilled = false;
                    }
                });
            });

            if (!allFieldsFilled) {
                notify("All option Value fields must be filled");
                return;
            }
        }

        try {
            const requestBody = {
                Universities: universities,
                Sports: sports,
                Types: types,
            };
            // console.log(requestBody)
            const validProducts = await fetchFilteredProducts(user, requestBody);

            setFilteredProducts(validProducts);
            setIsModalOpen(true);
            setNoProductsFound(validProducts.length === 0);

        } catch (error) {
            if (error.response && error.response.status === 404) {
                notify("No products found with the given filters");
                setNoProductsFound(true);
            } else if (error.response && error.response.status === 423) {
                notify("No products found with the given filters: Only 'Influxer School of Gains' is allowed in dev mode");
                setNoProductsFound(true);
            } else {
                console.error("Error fetching filtered products:", error);
                notify("Failed to fetch filtered products");
            }
        }finally {
            setIsSubmitting(false); // Unlock the form
        }
    };

    // Actually starting bulk op for Price and Cost
    const handleConfirmUpdate = (note) => {
        setIsSubmitting(true); // Lock the form

        // Create the payload with ProductID and specific options
        const productSpecificOptionsMap = {};
        for (const product of filteredProducts) {
            const specificOptionsForType = specificOptions[product.type];
            if (specificOptionsForType) {
                productSpecificOptionsMap[product.ShopifyProductID] = specificOptionsForType;
            }
        }

        const requestBody = {
            InputFilters: `University Choice(s): ${universities}, Sports Choice(s): ${sports}, Type Choice(s): ${types}, Options and Value Pair: ${JSON.stringify(specificOptions)}`,
            userName: user.displayName,
            userNotes: note,
            updateType: updateType,
            products: productSpecificOptionsMap
        };

        ValueUpdate(user, requestBody)
            .then(() => {
                setIsModalOpen(false);
                notify({
                    msg: `${updateType} Update Request Sent Out Successfully`,
                    variant: "success",
                });
                // setRefreshOperations(prev => !prev);
            })
            .catch(error => {
                console.error("Error updating prices:", error);
                notify("Failed to update prices");
            })
            .finally(() => {
                setIsSubmitting(false); // Unlock the form
            });
    };

    // Actually starting bulk op for Description
    const handleDescriptionConfirmUpdate = (note) => {
        setIsSubmitting(true); // Lock the form

        // Create the payload with ProductID and descriptions
        const productDescriptionMap = {};
        for (const product of filteredProducts) {
            const description = specificOptions[product.type]?.description;
            if (description) {
                productDescriptionMap[product.ShopifyProductID] = description;
            }
        }

        const requestBody = {
            InputFilters: `University Choice(s): ${universities}, Sports Choice(s): ${sports}, Type Choice(s): ${types}, Type and Description Pair: ${JSON.stringify(specificOptions)}`,
            userName: user.displayName,
            userNotes: note,
            updateType: "Description",
            products: productDescriptionMap
        };

        DescriptionUpdate(user, requestBody)
            .then(() => {
                setIsModalOpen(false);
                notify({
                    msg: `Description Update Request Sent Out Successfully`,
                    variant: "success",
                });
            })
            .catch(error => {
                console.error("Error updating descriptions:", error);
                notify("Failed to update descriptions");
            })
            .finally(() => {
                setIsSubmitting(false); // Unlock the form
            });
    };

    // Start Bulk Op for Archive
    const handleArchiveConfirmUpdate = (note) => {
        const requestBody = {
            InputFilters: `Archiving On: ${archiveSKUs}`,
            userName: user.displayName,
            userNotes: note,
            metadataIds: selectedProducts
        };

        ArchiveUpdate(user, requestBody)
            .then(() => {
                setIsModalOpen(false);
                setRefreshTable(prev => !prev);
                notify({
                    msg: "Archive Update Request Sent Out Successfully",
                    variant: "success",
                });
            })
            .catch(error => {
                console.error("Error updating archive status:", error);
                notify("Failed to update archive status");
            });
        setArchiveSKUs([])
    };

    const handleVariantsCreationConfirmUpdate = (note) => {
        const requestBody = {
            InputFilters: variantsCreationType,
            userName: user.displayName,
            userNotes: note,
            ProductsNOptions: productSizeData
        };
        console.log("Request Body", requestBody)
        VariantsCreationUpdate(user, requestBody)
            .then(() => {
                setIsModalOpen(false);
                setClearState(prev => !prev);
                notify({
                    msg: "Variants Creation Request Sent Out Successfully",
                    variant: "success",
                });
            })
            .catch(error => {
                console.error("Error Sending out Variants Creation Request:", error);
                notify("Failed to Send out Variants Creation Request");
            });
        setArchiveSKUs([])
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <WebSocketProvider>
        <Container>
            <Typography variant="h4" gutterBottom>Welcome to the Audible Page!</Typography>
            <Typography variant="body1" gutterBottom>This Page is for Audible: Bulk Updates.</Typography>
            <Tabs
                value={updateType}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                {updateTypes.map((type) => (
                    <Tab key={type} label={type} value={type} />
                ))}
            </Tabs>
            {updateType && (
                <>
                    {(updateType === "Price" || updateType === "Cost") && (
                        <>
                            {/*UniversityOptions, universities, setUniversities, SportOptions, sports, setSports, TypeOptions, types, setTypes, isSubmitting, updateType*/}
                            <form onSubmit={handleSubmit}>
                                <ProductFilter
                                    UniversityOptions={UniversityOptions}
                                    universities = {universities}
                                    setUniversities = {setUniversities}
                                    SportOptions = {SportOptions}
                                    sports = {sports}
                                    setSports = {setSports}
                                    TypeOptions = {TypeOptions}
                                    types = {types}
                                    setTypes = {setTypes}
                                    isSubmitting = {isSubmitting}
                                    updateType = {updateType}
                                />
                                {types.length > 0 && (
                                    <Box sx={{ marginTop: '16px' }}>
                                        {types.map((type) => (
                                            <Paper key={type} elevation={3} sx={{ margin: '16px 0', padding: '16px' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="h6">{type}</Typography>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={isUniformPrice[type] || false}
                                                                onChange={(e) => {
                                                                    const newUniformPrice = e.target.checked;
                                                                    setIsUniformPrice(prev => ({
                                                                        ...prev,
                                                                        [type]: newUniformPrice
                                                                    }));
                                                                    if (newUniformPrice) {
                                                                        // Apply uniform price to all options of thsi type
                                                                        const uniformPrice = uniformPrices[type] || '';
                                                                        const updatedOptions = {
                                                                            ...specificOptions,
                                                                            [type]: {}
                                                                        };
                                                                        typeOptionsMap[type]?.forEach(option => {
                                                                            updatedOptions[type][option] = uniformPrice;
                                                                        });
                                                                        setSpecificOptions(updatedOptions);
                                                                    }
                                                                }}
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                            />
                                                        }
                                                        label={`Uniform Value`}
                                                    />
                                                </Box>
                                                {isUniformPrice[type] ? (
                                                    <TextField
                                                        fullWidth
                                                        label={`Uniform ${updateType} for ${type}`}
                                                        type="number"
                                                        value={uniformPrices[type] || ''}
                                                        onChange={(e) => {
                                                            const newPrice = e.target.value;
                                                            setUniformPrices(prevPrices => ({
                                                                ...prevPrices,
                                                                [type]: newPrice
                                                            }));
                                                            // Update all options of this type with the new uniform price
                                                            const updatedOptions = {
                                                                ...specificOptions,
                                                                [type]: {}
                                                            };
                                                            typeOptionsMap[type]?.forEach(option => {
                                                                updatedOptions[type][option] = newPrice;
                                                            });
                                                            setSpecificOptions(updatedOptions);
                                                        }}
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled={isSubmitting}
                                                    />
                                                ) : (
                                                    <Grid container spacing={2}>
                                                        {typeOptionsMap[type]?.map((option) => (
                                                            <Grid item xs={6} key={option}>
                                                                <TextField
                                                                    label={`${type} - ${option}`}
                                                                    type="number"
                                                                    value={specificOptions[type]?.[option] || ''}
                                                                    onChange={(e) => handleSpecificOptionChange(type, option, e.target.value)}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    required
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Paper>
                                        ))}
                                    </Box>
                                )}
                                <Box sx={{display: 'flex', gap: '16px', marginTop: '16px'}}>
                                    <Button
                                        sx={{textTransform: 'unset'}}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting} // Disable the button
                                    >
                                        Start Bulk Operation
                                    </Button>
                                </Box>
                            </form>
                            <ValueUpdateSummaryModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                filteredProducts={filteredProducts}
                                noProductsFound={noProductsFound}
                                handleConfirmUpdate={handleConfirmUpdate}
                                handleDescriptionConfirmUpdate = {handleDescriptionConfirmUpdate}
                                updateType={updateType}
                                universities = {universities}
                                sports = {sports}
                                types = {types}
                                isSubmitting = {isSubmitting}
                                specificOptions={specificOptions}
                            />
                        </>
                    )}
                    {updateType === "Description" && (
                        <>
                            <form onSubmit={handleSubmit}>
                                <ProductFilter
                                    UniversityOptions={UniversityOptions}
                                    universities = {universities}
                                    setUniversities = {setUniversities}
                                    SportOptions = {SportOptions}
                                    sports = {sports}
                                    setSports = {setSports}
                                    TypeOptions = {TypeOptions}
                                    types = {types}
                                    setTypes = {setTypes}
                                    isSubmitting = {isSubmitting}
                                    updateType = {updateType}
                                />
                                {types.length > 0 && (
                                    <>
                                        {types.length > 1 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={isUniformDescription}
                                                            onChange={(e) => setIsUniformDescription(e.target.checked)}
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                        />
                                                    }
                                                    label="Uniform Description"
                                                />
                                            </Box>
                                        )}
                                        {isUniformDescription && (
                                            <Box sx={{ marginTop: '16px' }}>
                                                <Paper elevation={3} sx={{ margin: '16px 0', padding: '16px' }}>
                                                    <Typography variant="h6">Uniform Description for All Types</Typography>
                                                    <ReactQuill
                                                        value={uniformDescription}
                                                        onChange={handleUniformDescriptionChange}
                                                        theme="snow"
                                                        readOnly={isSubmitting}
                                                    />
                                                </Paper>
                                            </Box>
                                        )}
                                        {!isUniformDescription && (
                                            <Box sx={{ marginTop: '16px' }}>
                                                {types.map((type) => (
                                                    <Paper key={type} elevation={3} sx={{ margin: '16px 0', padding: '16px' }}>
                                                        <Typography variant="h6">{`Description for ${type}`}</Typography>
                                                        <ReactQuill
                                                            value={specificOptions[type]?.description || ''}
                                                            onChange={(value) => handleSpecificOptionChange(type, 'description', value)}
                                                            theme="snow"
                                                            readOnly={isSubmitting}
                                                        />
                                                    </Paper>
                                                ))}
                                            </Box>
                                        )}
                                    </>
                                )}
                                <Box sx={{display: 'flex', gap: '16px', marginTop: '16px'}}>
                                    <Button
                                        sx={{textTransform: 'unset'}}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting} // Disable the button
                                    >
                                        Start Bulk Operation
                                    </Button>
                                </Box>
                                <ValueUpdateSummaryModal
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    filteredProducts={filteredProducts}
                                    noProductsFound={noProductsFound}
                                    handleConfirmUpdate={handleConfirmUpdate}
                                    handleDescriptionConfirmUpdate = {handleDescriptionConfirmUpdate}
                                    updateType={updateType}
                                    universities = {universities}
                                    sports = {sports}
                                    types = {types}
                                    isSubmitting = {isSubmitting}
                                    specificOptions={specificOptions}
                                />
                            </form>
                        </>
                    )}

                    {updateType === "Archive" && (
                        <>
                            <Box sx={{marginTop: '16px'}}>
                                <div>
                                    <ProductMetadataTable
                                        user={user}
                                        notify={notify}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={setSelectedProducts}
                                        refresh={refreshTable}
                                        setArchiveSKUs = {setArchiveSKUs}
                                    />
                                </div>
                                <Button
                                    sx={{textTransform: 'unset'}}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleArchiveSubmit([])}
                                    disabled={isSubmitting} // Disable the button
                                >
                                    Start Bulk Operation
                                </Button>
                            </Box>
                            {summaryDetails && (
                                <ArchiveSummaryModal
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    summaryDetails={summaryDetails}
                                    handleConfirmUpdate={handleArchiveConfirmUpdate}
                                    isSubmitting={isSubmitting}
                                />
                            )}
                        </>

                    )}
                    {updateType === "Product Audit" && (
                        <>
                            <Box sx={{marginTop: '16px'}}>
                                <div>
                                    <CollectionSelection
                                        user={user}
                                        selectedCollections={selectedCollections}
                                        setSelectedCollections={setSelectedCollections}
                                        refresh={refreshCollectionTable}
                                    />
                                </div>
                            </Box>
                            <Button
                                sx={{textTransform: 'unset'}}
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => handleProductAuditSubmit([])}
                                disabled={isSubmitting} // Disable the button
                            >
                                Start Bulk Operation
                            </Button>
                            <ProductAuditSummaryModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                selectedCollections={selectedCollections}
                                handleConfirmUpdate={handleProductAuditConfirmQuery}
                                isSubmitting={isSubmitting}
                            />
                        </>
                    )}
                    {updateType === "Variants Creation" && (
                        <>
                            <div>
                                <VariantsCreation
                                    TypeOptions={TypeOptions}
                                    setVariantsCreationType = {setVariantsCreationType}
                                    notify={notify}
                                    user={user}
                                    setProductSizeData={setProductSizeData}
                                    setIsModalOpen={setIsModalOpen}
                                    clearState = {clearState}
                                />
                            </div>
                            <VariantsCreationSummaryModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                variantProducts={productSizeData}
                                handleConfirmUpdate={handleVariantsCreationConfirmUpdate}
                                isSubmitting={isSubmitting}
                            />
                        </>
                    )}
                </>
            )}
            <Box>
                <OperationsTable
                    user = {user}
                    refreshTrigger={refreshOperations}
                    setRefreshTrigger = {setRefreshOperations}
                />
            </Box>
        </Container>
        </WebSocketProvider>
    );
}

export default AudibleComponent;