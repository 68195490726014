import { Button, LinearProgress, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { updateVerified } from "../../../API/equipmentManager";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";


const TaskDetailedTable = ({ loading, setLoading, data, taskType }) => {
    const user = useUserContext();
    const notify = useNotification();
    const filteredData = data.map((obj) => {
        delete obj.equipment_manager_record;

        return obj;
    });
    function capitalizeAllWords(input) {
        return input.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }
    var columns = []
    if (filteredData.length > 0) {
        columns = Object.keys(filteredData[0]).map(key => {
            if (key.includes("link")) {
                return {
                    name: key,
                    label: capitalizeAllWords(key.replaceAll("_", " ")),
                    options: {
                        filter: true,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <img
                                alt={value}
                                style={{ maxHeight: "50px" }}
                                src={value}
                                onClick={() => {
                                    window.open(value, "_blank");
                                }}
                            />
                        ),
                    },

                }
            } else if (key == "is_verified") {
                return {
                    name: key,
                    label: capitalizeAllWords(key.replaceAll("_", " ")),
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value) {
                                return <Typography>Verified</Typography>
                            }
                            return (< Button sx={{
                                margin: "10px",
                                backgroundColor: "success.main",
                                color: "black",
                            }}
                                onClick={() => {
                                    let configdict = {
                                        "metadata_id": tableMeta.rowData[0],
                                        "task_type": taskType
                                    }
                                    updateVerified(user, configdict).then((response) => {
                                        notify({ msg: "Succesfully Updated Verified Status", variant: "success" })
                                    }).catch((error) => {
                                        notify("Failed to update verified status")
                                    })
                                }
                                }
                                disabled={value}
                            >
                                {value ? "Verified" : "Click to Verify"}
                            </Button >)
                        }
                    },
                }
            }
            return {
                name: key,
                label: capitalizeAllWords(key.replaceAll("_", " ")),
                options: {
                    filter: true,
                    sort: key == "University",
                },
            }
        });
    }
    const options = {
        filter: true,
        selectableRows: false,
    };
    return (

        <div style={{ width: "100%", marginLeft: "20px", paddingRight: "50px" }}>
            {loading ? <LinearProgress /> : <>
                <MUIDataTable
                    style={{ width: "90%" }}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </>}

        </div>
    );
};

export default TaskDetailedTable;
