import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getAthleteUserById = (id, user, setAthleteUser) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  axios
    .get(BALLPARK_BACKEND_BASE_URL + "getUserById?id=" + id, {
      headers,
    })
    .then((response) => {
      setAthleteUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getMerchRosterById = (id, user, setMerchRoster, callback) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  axios
    .get(BALLPARK_BACKEND_BASE_URL + "searchRosterById?rosterId=" + id, {
      headers,
    })
    .then((response) => {
      setMerchRoster(response.data);
      callback();
    })
    .catch((error) => {
      console.error(error);
      callback();
    });
};

export const searchStudentAthleteByName = (name, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "searchForStudentAthlete?search=" + name,
    {
      headers,
    }
  );
};
