import {
  Typography,
  Button,
  LinearProgress,
  Box,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import * as React from "react";
import { useUserContext } from "../../../contexts/ProviderProvider";
import {
  getCollectionsReady,
  getCurrentlyRunning,
  getProductsReady,
  runHattrickOn,
  stopHattrick,
} from "../../../API/hattrick";
import CurrentlyRunning from "./CurrentlyRunning";
import HattrickProductReadyTable from "./HattrickProductReadyTable";
import HattrickCollectionReadyTable from "./HattrickCollectionsReadyTable";
import SchoolSportFilter from "../../common/SchoolSportFilter";
import { useNavigate } from "react-router-dom";
import useNotification from "../../../hooks/useNotification";
import { getUniversityProductCount } from "../../../API/products";
import { getTotalLive } from "../../../API/roster";
import GrandSlamRunningAnalytics from "./GrandSlamRunningAnalytics";

const GrandSlam = (props) => {
  const [selectedSchool, setSelectedSchool] = React.useState(props.school);
  const [selectedSport, setSelectedSport] = React.useState(props.sport);
  const [products, setProducts] = React.useState([]);
  const [collections, setCollections] = React.useState([]);
  const [currentlyRunning, setCurrentlyRunning] = React.useState([]);
  const [totalLive, setTotalLive] = React.useState(-1)

  const [activeTab, setActiveTab] = React.useState(0);
  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [universityCount, setUniversityCount] = React.useState({});
  const [canRun, setCanRun] = React.useState(true)

  const navigate = useNavigate();
  const notify = useNotification();
  const user = useUserContext();

  const updateUniversityCount = (results) => {
    var temp = {};
    const product_results = results["products"];
    for (let result in product_results) {
      const result_university = product_results[result]["schoolName"];
      const count = product_results[result]["selectedCount"];
      temp[result_university] = [count, 0];
    }
    const collection_results = results["collections"];
    for (let result in collection_results) {
      const result_university = collection_results[result]["schoolName"];
      const count = collection_results[result]["selectedCount"];
      if (temp[result_university] !== undefined) {
        console.log(temp[result_university], result_university);
        temp[result_university] = [temp[result_university][0], count];
      } else {
        temp[result_university] = [0, count];
      }
    }
    setUniversityCount(temp);
  };

  const setSelectedSportViaUrl = (sport) => {
    //console.log("redirecting sport");
    navigate(
      "/ballpark/grandslam" +
      (selectedSchool ? "/" + btoa(selectedSchool) : "") +
      (sport ? "/" + btoa(sport) : "")
    );
  };

  const setSelectedSchoolViaUrl = (school) => {
    navigate(
      "/ballpark/grandslam" +
      (school ? "/" + btoa(school) : "") +
      (selectedSport ? "" + btoa(selectedSport) : "")
    );
  };

  const updateCurrentlyRunning = React.useCallback(
    (newVal) => {
      if (newVal.length !== currentlyRunning.length) {
        setCurrentlyRunning(newVal);
        setRefresh((p) => p + 1);
      }
    },
    [currentlyRunning]
  );

  const stopGrandSlam = () => {
    setLoading(true)
    stopHattrick(user)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to stop. Grandslam cannot be contained");
        setLoading(false);
      });
  }

  React.useEffect(() => {
    if (!user) return;
    console.log(selectedSchool)
    if ((selectedSchool || selectedSport)) {
      if (activeTab === 0 && products.length <= 0) {
        setCanRun(false)
      }
      else if (activeTab !== 0 && collections.length <= 0) {
        setCanRun(false)
      }
      else {
        setCanRun(true)
      }
    }
    else {
      setCanRun(true)
    }
  }, [canRun, activeTab, selectedSchool, selectedSport, products, collections, user])

  React.useEffect(() => {
    if (!user) return;
    if (selectedSchool?.length > 3) {
      setLoading(true);
      getCollectionsReady(selectedSchool, selectedSport, user)
        .then((response) => {
          setCollections(response.data.obj);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get collections");
          setCollections([]);
          setLoading(false);
        });
    }
    else {
      setCollections([]);
    }
  }, [user, selectedSchool, selectedSport, refresh, notify]);

  React.useEffect(() => {
    if (!user) return;
    if (selectedSchool?.length > 3) {
      setLoading(true);
      getProductsReady(selectedSchool, selectedSport ? selectedSport : "", user)
        .then((response) => {
          setProducts(response.data.obj);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get ready products");
          setLoading(false);
          setProducts([]);
        });
    }
    else {
      setProducts([]);
    }
  }, [user, selectedSchool, selectedSport, refresh, notify]);

  React.useEffect(() => {
    setSelectedSchool(props.school);
    setSelectedSport(props.sport);
  }, [props.school, props.sport]);

  React.useEffect(() => {
    if (!user) return;
    getCurrentlyRunning(user)
      .then((response) => {
        updateCurrentlyRunning(response.data.obj);
      })
      .catch((error) => {
        notify("Failed to get hattrick running state");
        updateCurrentlyRunning([]);
      });
  }, [refresh, updateCurrentlyRunning, user, notify]);

  React.useEffect(() => {
    if (!user) return;
    const pollId = setInterval(() => {
      // dont poll the server if the tab isnt visible
      if (!document.hidden) {
        getCurrentlyRunning(user)
          .then((response) => {
            updateCurrentlyRunning(response.data.obj);
          })
          .catch((error) => {
            notify("Failed to get hattrick running state");
            setCurrentlyRunning([]);
          });
      }
    }, 5000);

    return () => {
      clearInterval(pollId);
    };
  }, [user, updateCurrentlyRunning, notify]);

  React.useEffect(() => {
    getUniversityProductCount(user)
      .then((response) => {
        updateUniversityCount(response.data);
      })
      .catch((error) => {
        notify("Failed to get product university counts");
      });
  }, [user, updateCurrentlyRunning, refresh, notify]);

  React.useEffect(() => {
    getTotalLive(user)
      .then((response) => {
        setTotalLive(response.data.total_live)
      })
      .catch((error) => {
        notify("Failed to get total live count");
      });
  }, [user, notify])

  const runAll = () => {
    if (activeTab === 0) {
      runHattrickOn(
        {
          running: products.map((p) => ({
            type_id: p.ProductId,
            roster_id: p.RosterId,
          })),
          type: "products",
        },
        user,
        () => setRefresh((p) => p + 1)
      )
        .then((response) => {
          setRefresh((p) => p + 1);
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get hattrick running state");
          setRefresh((p) => p + 1);
        });
    } else {
      runHattrickOn(
        {
          running: collections.map((p) => ({
            type_id: p.CollectionId,
            roster_id: p.RosterId,
          })),
          type: "collections",
        },
        user
      )
        .then((response) => {
          setRefresh((p) => p + 1);
        })
        .catch((error) => {
          notify("Failed to get hattrick running state");
          console.error(error);
          setRefresh((p) => p + 1);
        });
    }
  };

  if (!user) {
    return <></>;
  }
  return (
    <div style={{ width: "80%" }}>
      <Typography variant="h1">Grand Slam</Typography>
      {totalLive > 0 &&
        <div>
          <Typography variant="h6">Total Athletes Taken Live: {totalLive.toString()}</Typography>
          <br />
        </div>}
      <span
        style={{
          opacity: "0.4",
          fontSize: "14",
          marginLeft: "0px",
          position: "relative",
          top: "-10px",
        }}
      >
        {!activeTab ? "Products " : "Collections "} ready for Grandslam!
      </span>
      <br />
      <Button variant="outlined" color="error" onClick={stopGrandSlam}>Stop Slammin'</Button>
      <CurrentlyRunning
        currentlyRunning={currentlyRunning}
        products={products}
        collections={collections}
      />
      <SchoolSportFilter
        setSelectedSchool={setSelectedSchoolViaUrl}
        setSelectedSport={setSelectedSportViaUrl}
        selectedSchool={selectedSchool}
        selectedSport={selectedSport}
        universitiesCount={universityCount}
      />
      <Divider sx={{ marginTop: "15px", marginBottom: "10px" }} />
      <Button sx={{ width: "100%" }} variant={"contained"} onClick={runAll} disabled={!canRun}>
        Run All {activeTab === 0 ? "Products" : "Collections"} With Current
        Filter
      </Button>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={activeTab} onChange={(e, nv) => setActiveTab(nv)}>
          <Tab label="Products" />
          <Tab label="Collections" />
          <Tab label="Analytics" />
        </Tabs>
      </Box>
      {(Object.keys(currentlyRunning).length > 0 || loading) && (
        <LinearProgress />
      )}
      <div hidden={activeTab !== 0}>
        <HattrickProductReadyTable
          currentlyRunning={currentlyRunning}
          data={products}
          refresh={() => setRefresh((p) => p + 1)}
        />
      </div>
      <div hidden={activeTab !== 1}>
        <HattrickCollectionReadyTable
          currentlyRunning={currentlyRunning}
          data={collections}
          refresh={() => setRefresh((p) => p + 1)}
        />
      </div>
      {activeTab == 2 &&
        <div>
          <GrandSlamRunningAnalytics />
        </div>}
    </div >
  );
};

export default GrandSlam;

