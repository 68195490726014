import * as React from "react";
import { Grid } from "@mui/material";
import Navbar from "../components/common/Navbar.jsx";
import NavDrawer from "../components/common/NavDrawer.jsx";
import { useNavigate } from "react-router-dom";
import { Welcome } from "../components/modules/Welcome/index.jsx";
import ChangeLogDrawer from "../components/common/NotesDrawer.jsx";
import { isSmallScreen } from "../utilities/themeUtilities.js";
import { useGlobalThemeContext } from "../contexts/ProviderProvider.jsx";
import Can from "../components/common/Can.jsx";

const PageRenderContext = React.createContext({
  toRender: "",
  setToRender: () => { },
});

export default function Dashboard(props) {
  const { isNavOpen, setIsNavOpen } = useGlobalThemeContext();
  const [isNotesOpen, setIsNotesOpen] = React.useState(false);
  const [toRender, setToRender] = React.useState("Welcome");
  const [dynamicList, setDynamicList] = React.useState({});
  const navigate = useNavigate();

  const drawerRef = React.useRef();
  const initialWidth = window.innerWidth < 1000 ? window.innerWidth : "15vw";
  const [navbarWidth, setNavbarWidth] = React.useState(initialWidth);
  const [maxNavBarWidth, setMaxNavBarWidth] = React.useState(window.innerWidth);
  // const [selectedPath, setSelectedPath] = React.useState("Welcome");
  const drawerWidth = {
    width: navbarWidth,
    maxWidth: maxNavBarWidth,
    minWidth: "240px",
  };

  React.useEffect(() => {
    const componentContext = require.context(
      "../components/modules",
      true,
      /index\.jsx$/
    );
    var componentModules = componentContext.keys().map(componentContext);
    // remove upload assets
    componentModules = componentModules.filter((comp) => {
      return comp.componentName != "Upload Assets" && comp.componentName != "Marathon"
    })
    const dynamicPages = {
      Welcome: {
        Component: Welcome,
        allow: [],
        componentName: "Welcome",
        icon: <div>I</div>,
        text: "Welcome",
        path: "ballpark",
        onClick: () => navigate("/ballpark"),
      },
    };
    componentModules.map(
      (mod) =>
      (dynamicPages[mod.componentName] = {
        text: mod.text,
        Component: mod.Component,
        allow: mod.allow,
        componentName: mod.componentName,
        icon: mod.icon,
        path: mod.path,
        onClick: () => {
          // setSelectedPath(mod.text);
          if (isSmallScreen()) {
            setIsNavOpen(false);
          }
          navigate("/ballpark/" + mod.path);
        },
      })
    );
    setDynamicList(dynamicPages);
  }, [navigate]);

  React.useEffect(() => {
    // Function to update navbar width based on screen size
    const updateNavbarWidth = () => {
      if (isSmallScreen()) {
        setNavbarWidth("100%");
      } else {
        setNavbarWidth("15vw");
      }
    };

    // Attach the event listener
    window.addEventListener("resize", updateNavbarWidth);

    // Call the function initially
    updateNavbarWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateNavbarWidth);
    };
  }, []);

  return (
    <div
      style={{
        minWidth: "100%",
        minHeight: "100%",
      }}
    >
      <PageRenderContext.Provider value={{ toRender, setToRender }}>
        <Navbar
          openNav={() => setIsNavOpen(true)}
          openNotes={() => setIsNotesOpen(true)}
        />
        {(!isNavOpen || (isNavOpen && !isSmallScreen())) && (
          <Grid
            container
            direction="column"
            justifyContent="top"
            alignItems="center"
            sx={{
              width: isNavOpen
                ? `calc(100% - ${drawerRef.current?.offsetWidth}px)`
                : "100%",
              margin: 0,
              position: "relative",
              left: isNavOpen ? drawerRef.current?.offsetWidth : "0",
              transition: "width 400ms, left 400ms",
            }}
            height="100%"
          >
            {props.children}
          </Grid>
        )}

        <NavDrawer
          drawerRef={drawerRef}
          drawerWidth={drawerWidth}
          open={isNavOpen}
          close={() => {
            setIsNavOpen(false);
          }}
          components={dynamicList ? dynamicList : {}}
          choosePage={setToRender}
        // selectedPath={selectedPath}
        />
        <Can notRole={"Compliance Admin"}>
          <ChangeLogDrawer
            isOpen={isNotesOpen}
            drawerWidth={drawerWidth}
            close={() => {
              setIsNotesOpen(false);
            }}
          />
        </Can>
      </PageRenderContext.Provider>
    </div>
  );
}

export const usePageRender = () => React.useContext(PageRenderContext);
