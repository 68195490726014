import { useEffect, useState } from "react";
import {
  Typography,
  Autocomplete,
  LinearProgress,
  TextField,
  ListItem,
} from "@mui/material";
import { useUserContext } from "../../../contexts/ProviderProvider.jsx";
import AthleteProfile from "./AthleteProfile.jsx";
import { searchRosterForName } from "../../../API/roster.js";
import Roster from "./Roster.jsx";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "../../../hooks/useNotification.jsx";
import { decodeURLVariable } from "../../../utilities/utils.js";

const AthleteProfileWrapper = (props) => {
  const { athleteIdEncoded, schoolEncoded } = useParams();
  console.log("encoding", athleteIdEncoded, schoolEncoded);
  const notify = useNotification();
  const user = useUserContext();
  const navigate = useNavigate();

  const [rosterList, setRosterList] = useState([]);
  const [input, setInput] = useState("");
  const [selectedMerchRoster, setSelectedMerchRoster] = useState(
    decodeURLVariable(athleteIdEncoded)
  );
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (selectedMerchRoster) {
      navigate("/ballpark/athletes/" + btoa(selectedMerchRoster));
    }
  }, [selectedMerchRoster, navigate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        !searchLoading &&
        input.length >= 2 &&
        rosterList.length === 0 &&
        selectedMerchRoster !== input
      ) {
        setSearchLoading(true);
        searchRosterForName(input, user)
          .then((response) => {
            if (response.data.length == 1) {
              setSelectedMerchRoster(response.data[0].idAthleteMerchInfo)
            }
            setRosterList(response.data);
            setSearchLoading(false);
          })
          .catch((error) => {
            console.error(error);
            notify("Failed to complete search");
            setRosterList([]);
            setSearchLoading(false);
          });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [
    input,
    user,
    rosterList?.length,
    searchLoading,
    selectedMerchRoster,
    notify,
  ]);

  const fetchUsers = (event, value) => {
    if (rosterList.length > 0) {
      setRosterList([]);
    }
    setInput(value);
  };

  if (schoolEncoded) {
    return (
      <Roster school={schoolEncoded ? atob(schoolEncoded) : schoolEncoded} />
    );
  }

  if (!user) {
    return <></>;
  }
  return (
    <div style={{ width: "80%", paddingTop: "5vh" }}>
      <div style={{ width: "50%", margin: "auto" }}>
        <Typography>Search</Typography>
        {searchLoading && <LinearProgress />}
        <Autocomplete
          options={rosterList}
          onInputChange={fetchUsers}
          onChange={(e, v) => {
            setSelectedMerchRoster(v?.idAthleteMerchInfo);
          }}
          getOptionLabel={(option) => option.full_name}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option, state) => (
            <ListItem
              {...props}
              key={option.idAthleteMerchInfo}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid white",
                backgroundColor: !option.signed
                  ? "error.main"
                  : "background.default",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                {option.jersey_number}
              </div>
              <div style={{ flex: 6 }}>{option.full_name}</div>
              <div
                style={{
                  flex: 4,
                }}
              >
                {option.sport}
              </div>
              <div
                style={{
                  flex: 3,
                }}
              >
                {option.university}
              </div>
            </ListItem>
          )}
        />
      </div>
      {selectedMerchRoster && <AthleteProfile rosterId={selectedMerchRoster} />}
    </div>
  );
};

export default AthleteProfileWrapper;
