import axios from "axios";

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;
const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const testAssetGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/assetGeneration/test", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const runAssetGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/assetGeneration/run", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const testMockupGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/mockupGeneration/test", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const runMockupGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/mockupGeneration/run", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};


export const getEquipmentManagerConfig = (user) => {
  return axios.get(BALLPARK_BACKEND_BASE_URL + "equipmentManagerConfig", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const insertEquipmentManagerConfig = (user, payload) => {
  console.log(payload);
  return axios.post(BALLPARK_BACKEND_BASE_URL + "equipmentManagerConfig", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const updateEquipmentManagerConfig = (user, payload) => {
  return axios.put(BALLPARK_BACKEND_BASE_URL + "equipmentManagerConfig", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const runAllAssetGeneration = (payload, user) => {
  return axios.post(BASE_HATTRICK_URL + "equipmentManager/assetGeneration/runAll", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const stopAssetGeneration = (user) => {
  return axios.post(BASE_HATTRICK_URL + "equipmentManager/assetGeneration/stop", {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const runAllMockupGeneration = (payload, user) => {
  return axios.post(BASE_HATTRICK_URL + "equipmentManager/mockupGeneration/runAll", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const stopMockupGeneration = (user) => {
  return axios.post(BASE_HATTRICK_URL + "equipmentManager/mockupGeneration/stop", {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const getEquipmentManagerStatus = (user) => {
  return axios.get(BASE_HATTRICK_URL + "equipmentManager/status", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}


export const testGraphicGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/socialGraphicsGeneration/test", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const runGraphicGeneration = (file, user) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(BASE_HATTRICK_URL + "equipmentManager/socialGraphicsGeneration/run", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const getRunnableAssets = (user) => {
  return axios.get(BALLPARK_BACKEND_BASE_URL + "equipmentManagerRunnableAssets", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const getRunnableMockups = (user) => {
  return axios.get(BALLPARK_BACKEND_BASE_URL + "equipmentManagerRunnableMockups", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}

export const updateVerified = (user, payload) => {
  return axios.put(BASE_HATTRICK_URL + "equipmentManager/verifyConfig", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  });
}