import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { TableRow, TableCell } from "@mui/material";
import AthleteProfileProductActions from "./AthleteProfileProductActions";
import AreYouSure from "../../../common/AreYouSure";
import { DetailedRowView } from "./ProductDetailedRow";
import { useUserContext } from "../../../../contexts/ProviderProvider";
import useNotification from "../../../../hooks/useNotification";
import { markAsArchived, updateProductStatus } from "../../../../API/hattrick";

const getAreYouSureMessage = (confirmType) => {
  switch (confirmType) {
    case "ARCHIVE":
      return 'Are you sure you want to mark this product as taken down? This will update the shopify product to "archived"';
    case "ACTIVE":
      return 'Are you sure you want to mark this product as Active? This will update the shopify product to "ACTIVE"';
    case "DRAFT":
      return 'Are you sure you want to mark this product as Draft? This will update the shopify product to "DRAFT"';
  }
};

const ProductsTable = ({ data, merchRoster, refresh, setLoading }) => {
  const [confirmType, setConfirmType] = useState();
  const [confirmChanges, setConfirmChanges] = useState();

  const user = useUserContext();
  const notify = useNotification();

  const confirmAreYouSure = (type, row) => {
    setConfirmType(type);
    console.log("row", row)
    setConfirmChanges({ product: convertToObject(row) });
  };

  const takeDown = () => {
    setLoading(true);
    markAsArchived(
      {
        shopify_product_ids: confirmChanges["product"].shopify_product_id ? [confirmChanges["product"].shopify_product_id] : undefined,
        product_ids: confirmChanges["product"].product_id ? [confirmChanges["product"].product_id.toString()] : undefined
      },
      user,
      refresh
    )
      .then((response) => {
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        notify("Failed to take down");
        console.error(error);
        refresh();
      });
  };
  const updateProductState = (status) => {
    setLoading(true);
    //console.log("Updating Product Status", status, confirmChanges);
    updateProductStatus(
      [confirmChanges["product"].shopify_product_id],
      status,
      user
    )
      .then((response) => {
        setLoading(false);
        refresh();
        notify({ msg: "Successfully updated status", variant: "success" });
      })
      .catch((error) => {
        setLoading(false);
        notify("Failed to update status");
        console.error(error);
        refresh();
      });
  };

  const submitAreYouSure = () => {
    switch (confirmType) {
      case "ARCHIVE":
        takeDown();
        break;
      case "ACTIVE":
        updateProductState("ACTIVE");
        break;
      case "DRAFT":
        updateProductState("DRAFT");
        break;
    }
  };

  const options = {
    rowsPerPage: window.rowsPerPage,
    enableNestedDataAccess: ".",
    expandableRows: true,
    selectableRows: "none",
    renderExpandableRow: (rowData, rowMeta) => {
      //console.log("row data:", rowData, rowMeta);

      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <DetailedRowView
              row={data[rowMeta.dataIndex]}
              refresh={refresh}
              merchRoster={merchRoster}
            />
          </TableCell>
        </TableRow>
      );
    },
  };

  const columns = [
    {
      label: "Photo",
      name: "photo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <img
            alt={"photo of product"}
            style={{ maxHeight: "50px" }}
            src={value}
            onClick={() => {
              window.open(value, "_blank");
            }}
          />
        ),
      },
    },
    { label: "Sku", name: "ProductMetadatum.sku_name" },
    { label: "Color", name: "ProductMetadatum.color" },
    { label: "Type", name: "ProductMetadatum.type" },
    {
      label: "Status",
      name: "state",
      options: {
        // Add default filter for Status = Product Active
        filter: true,
        filterList: ["Product Active"],
        filterOptions: {
          names: ["Product Active"],
          logic: (status, filterVal) => {
            if (filterVal.length === 0) return false;
            return !filterVal.includes(status);
          }
        }
      }
    },
    {
      label: "Back Asset",
      name: "backAsset",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <img
            alt={"photo of product"}
            style={{ maxHeight: "50px" }}
            src={value}
            onClick={() => {
              window.open(value, "_blank");
            }}
          />
        ),
      },
    },
    {
      label: "Front Asset",
      name: "frontAsset",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <img
            alt={"photo of product"}
            style={{ maxHeight: "50px" }}
            src={value}
            onClick={() => {
              window.open(value, "_blank");
            }}
          />
        ),
      },
    },
    {
      label: "School",
      name: "ProductMetadatum.university",
      options: { display: false },
    },
    { label: "Updated At", name: "modified_at", options: { display: false } },
    {
      label: "Shopify Product Id",
      name: "shopify_product_id",
      options: { display: false },
    },
    {
      label: "Product Id",
      name: "product_id",
      options: { display: false },
    },
    {
      label: "Action",
      name: "action",
      options: {
        empty: true,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <AthleteProfileProductActions
              confirmAreYouSure={confirmAreYouSure}
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
            />
          );
        },
      },
    },
  ];

  const convertToObject = (rowAsList) => {
    //console.log("rowAsList", rowAsList);
    const asObj = {};
    for (let i = 0; i < rowAsList.length; i++) {
      asObj[columns[i].name] = rowAsList[i];
    }
    return asObj;
  };

  const nullifyState = () => {
    setConfirmType(undefined);
    setConfirmChanges(undefined);
  };

  return (
    <div>
      <MUIDataTable options={options} columns={columns} data={data} />
      <AreYouSure
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "30vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={confirmType ? true : false}
        message={getAreYouSureMessage(confirmType)}
        handleClose={() => nullifyState()}
        yes={() => {
          submitAreYouSure();
          nullifyState();
        }}
        no={() => nullifyState()}
      />
    </div>
  );
};

export default ProductsTable;
