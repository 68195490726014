import { useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  Button,
  Typography,
  LinearProgress,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDateTime } from "../../../../utilities/utils";
import EditableCollection from "./EditableCollection";

const CollectionsTable = ({ loading, collections, refresh, reload }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [reload]);

  console.log(
    "c photo",
    collections && collections.length > 0 ? collections[0].photo : ""
  );

  return (
    <>
      <Typography
        variant="h5"
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
      >
        Collections
      </Typography>
      {loading && <LinearProgress />}
      {!loading && (
        <TableContainer
          sx={{
            overflowY: "visible",
            overflowX: "visible",
            height: "min-content",
          }}
          component={Paper}
        >
          <Table
            sx={{ minWidth: 650, minHeight: "fit-content" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell width={"48px"}></TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Visit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collections?.map((row) => (
                <>
                  <TableRow
                    key={row.collection_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <IconButton onClick={() => setIsOpen((p) => !p)}>
                        {isOpen ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ padding: "25px" }}
                    >
                      <img
                        alt="collection"
                        style={{ maxHeight: "50px" }}
                        src={row.photo}
                        onClick={() => {
                          window.open(row.photo, "_blank");
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{formatDateTime(row.modified_at)}</TableCell>
                    <TableCell>
                      {row.shopify_collection_id ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            window.open(
                              "https://admin.shopify.com/store/influxer-merch/collections/" +
                                row.shopify_collection_id,
                              "_blank"
                            );
                          }}
                        >
                          View
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <EditableCollection
                        rosterId={row.roster_id}
                        isOpen={isOpen}
                        refresh={refresh}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CollectionsTable;
