import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAdditionalUserInfo,
} from "firebase/auth";
import { createEmployee } from "./employee";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "influxeradmin.firebaseapp.com",
  projectId: "influxeradmin",
  storageBucket: "influxeradmin.appspot.com",
  messagingSenderId: "964209387357",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-MBQPR1MZ2R",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithEmailPass = async (username, password) => {
  try {
    const userLogin = await signInWithEmailAndPassword(auth, username, password)
    if (userLogin) {
      return userLogin;
    } else {
      return false;
    }
  } catch (error) {
    return({error:true, message:"Incorrect username or password"})
  }
}

export const forgotPassword = async(username) => {
  try {
    await sendPasswordResetEmail(auth,username);
    return({message:'Password reset email sent. Please check your email.'});
  } catch (error) {
    console.log(error.message);
    return({error:true, message:"Unable to send password reset email"})
  }
}

export const signInWithGoogle = async () => {
  const userLogin = await signInWithPopup(auth, provider);
  console.log("logged in with popup", userLogin);
  if (userLogin) {
    const domain = userLogin.user.email?.split("@")[1];
    console.log(domain);

    if (domain !== "influxer.com") {
      await auth.signOut();
      await auth.updateCurrentUser(null);
      return { error: "Not influxer account" };
    }

    return userLogin;
  } else {
    return false;
  }
};

const createNewEmployee = async (userLogin) => {
  await createEmployee(userLogin);
};

export const getToken = async (user) => {
  const access_token = await auth.currentUser.getIdToken(true);
  if (access_token) {
    console.log("success", access_token);
  } else {
    console.log("error", access_token);
  }
};

export const signOutWithGoogle = async () => {
  await auth.signOut();
};
