
import { useEffect, useState, useRef } from "react";
import { Box, Button, CircularProgress, TableCell, TableRow, List, ListItem, ListItemText, Typography, LinearProgress, MenuItem, Select, Modal } from "@mui/material";
import useNotification from "../../../hooks/useNotification";
import MUIDataTable from "mui-datatables";
import { getOrderData, updateOutfielderOrderStatus } from "../../../API/orderHistory";
import AreYouSure from "../../common/AreYouSure";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MarathonTable from "./MarathonTable";
import { startMarathonInfluxer } from "../../../API/marathon";
import { useUserContext } from "../../../contexts/ProviderProvider";

const InfluxerVendor = () => {
    const notify = useNotification();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [orderData, setOrderData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const today = new Date();
    const startOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const [startDate, setStartDate] = useState(dayjs(startOfCurrentMonth.toISOString().split('T')[0]));
    const [endDate, setEndDate] = useState(dayjs(today.toISOString().split('T')[0]));
    const user = useUserContext();
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const [skipGettingData, setSkipGettingData] = useState(false);

    const convertSortedIndexNumber = (tableMeta) => {
        return tableMeta.currentTableData[tableMeta.rowIndex].index;
    };

    useEffect(() => {
        startDateRef.current = startDate
    }, [startDate])


    useEffect(() => {
        endDateRef.current = endDate
    }, [endDate])



    // Fetch order data at regular intervals
    useEffect(() => {
        const getAlerts = () => {

            if (selectedIds.length == 0 && !skipGettingData) {
                getOrderData(startDateRef.current, endDateRef.current, user).then((response) => {
                    if (response.data.obj) {
                        setOrderData(response.data.obj);
                    } else {
                        setOrderData([]);
                    }
                }).catch((error) => {
                    console.error(error);
                    notify("Failed to get order data");
                });
            } else {
                setSkipGettingData(false);
            }
        };
        getAlerts();
        const interval = setInterval(() => getAlerts, 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    // Refresh order data on start date or end date change
    useEffect(() => {
        setLoading(true);
        getOrderData(startDate, endDate, user).then((response) => {
            if (response.data.obj) {
                setOrderData(response.data.obj);
                setSkipGettingData(true);
            } else {
                setOrderData([]);
            }
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            notify("Failed to get order data");
            setLoading(false);
        });
    }, [refresh, startDate, endDate, notify]);

    // Update the production status of an order
    const updateProductionStatus = (order_id) => {
        updateOutfielderOrderStatus(order_id, user).then(() => {
            notify({ msg: "Order status updated", variant: "success" });
            setRefresh(refresh + 1);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            notify("Failed to update order status");
            setLoading(false);
        });
    };

    // Handle submission confirmation
    const submitAreYouSure = (order_id) => {
        updateProductionStatus(order_id);
        nullifyState();
    };

    // Reset state
    const nullifyState = () => {
        setOrderId("");
        setIsModalOpen(false);
    };

    // Handle row selection change
    const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
        const selected = allRowsSelected.map((row) => orderData[row.dataIndex].shopify_order_id);
        setSelectedIds(selected);
    };

    // Export selected order data and open modal
    const exportData = () => {
        const selectedData = orderData.filter(order => selectedIds.includes(order.shopify_order_id));
        if (selectedData.length === 0) {
            notify({ msg: "No rows selected", variant: "warning" });
            return;
        }
        const updatedSelectedData = selectedData.map(order => ({ ...order, side: "Both" }));
        setLoading(true);

        var lineItemData = [];
        for (const order of selectedData) {

            if (order.line_items) {

                for (const lineItem of order.line_items) {
                    for (var i = 0; i < lineItem.line_quantity; i++)
                        lineItemData.push({
                            ...lineItem,
                            name: order.order_name,
                            line_quantity: 1,
                            side: "Both",
                        });

                }
            }
        }

        setData(lineItemData);

        setLoading(false);
        setOpen(true);
    };

    // Handle side change for selected orders
    const handleSideChange = (index, value) => {
        const updatedData = [...data];
        updatedData[index].side = value;
        setData(updatedData);
    };

    // Modal open and close handlers
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Column definitions for MUIDataTable
    const columns = [
        { label: "Order ID", name: "order_id" },
        { label: "Order Name", name: "order_name" },
        {
            label: "Shopify Order ID", name: "shopify_order_id",
            // onclick redirect to 
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Button sx={{ textTransform: 'unset' }}
                            onClick={() => {
                                window.open("https://admin.shopify.com/store/influxer-merch/orders/" + value)
                            }
                            }
                        >
                            {value}
                        </Button>
                    )
                },
            }
        },
        { label: "Production Status", name: "production_status" },
        { label: "University", name: "university" },
        { label: "Order Delivery Address", name: "address" },
        {
            label: "Number of Items", name: "line_items_length",

        },
        {
            label: "Send To Ninja",
            name: "send_to_ninja",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 6, bgcolor: "warning.light" }}
                        onClick={() => { setOrderId(orderData[convertSortedIndexNumber(tableMeta)].order_name); setIsModalOpen(true); }}
                    >
                        Send Order to Ninja
                    </Button>
                ),
            },
        }
    ];

    // Table options for MUIDataTable
    const options = {
        rowsPerPage: window.rowsPerPage,
        scrollX: true,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : "No Records",
            },
        },
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => (
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <List>
                        {orderData[rowMeta.dataIndex]["line_items"] ? orderData[rowMeta.dataIndex]["line_items"].map((dataMap, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${dataMap["variant_title"]} - ${dataMap["line_quantity"]}`} />
                            </ListItem>
                        )) : <Typography variant="h6" sx={{ padding: "20px" }}>No Variants Data, Please Contact Engineering Team</Typography>}
                    </List>
                </TableCell>
            </TableRow>
        ),
        onRowSelectionChange: handleRowSelectionChange,
    };

    // Column definitions for MarathonTable modal
    const modalColumns = [
        {
            name: "name", label: "Order Name",
            options: {

                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },
        {
            name: "line_quantity", label: "Quantity", options: {
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center' }
                })
            }
        },
        {
            name: "variant_title", label: "Variant Title",
            options: {
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { justifyContent: 'center' }
                }),
                customBodyRender: (value, tableMeta) => (

                    <Button sx={{ textTransform: 'unset' }}
                        onClick={() => {
                            window.open("https://admin.shopify.com/store/influxer-merch/products/" + data[convertSortedIndexNumber(tableMeta)].shopify_product_id)
                        }
                        }
                    >
                        {value}
                    </Button>

                ),
            }
        },
        {
            name: "side",
            label: "Side",
            options: {
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value, tableMeta) => (
                    <Select
                        value={value || 'Both'}
                        onChange={(e) => handleSideChange(convertSortedIndexNumber(tableMeta), e.target.value)}
                    >
                        <MenuItem value="Front">Front</MenuItem>
                        <MenuItem value="Back">Back</MenuItem>
                        <MenuItem value="Both">Both</MenuItem>
                    </Select>
                ),
            },
        },
        {
            name: "athlete_id",
            label: "Athlete ID",
            options: {
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center' }
                }),
                display: false
            }
        },
        {
            name: "product_id",
            label: "Product ID",
            options: {
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                setCellHeaderProps: () => ({
                    style: { textAlign: 'center' }
                }),
                display: false
            }
        }
    ];

    // Handle marathon execution
    const runMarathonAction = () => {
        const marathonRequests = data.map(row => ({
            name: row.order_name,
            line_item_quantity: row.lineitem_quantity,
            Line_item_name: row.lineitem_name,
            side: row.side,
            athlete_id: row.athlete_id,
            shopify_product_id: row.shopify_product_id
        }));
        startMarathonInfluxer(marathonRequests, user)
            .then(response => {
                if (response.Success) {
                    notify({ msg: "Marathon started successfully", variant: "success" });
                } else {
                    console.error("Marathon failed to start: ", response.errorText);
                    notify({ msg: response.errorText || "Failed to start marathon", variant: "error" });
                }
            })
            .catch(error => {
                console.error("Marathon run error: ", error);
                notify({ msg: "Failed to start marathon", variant: "error" });
            });
    };

    return (
        <>
            <Box sx={{ width: "90%", backgroundColor: "background.paper", marginTop: "1%" }} >
                <Box display="flex" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingRight: '5px', paddingBottom: '10px' }}>
                                <DateTimePicker
                                    label="Start Date"
                                    views={['month', 'year', 'day']}
                                    openTo="day"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    sx={{ margin: '10px' }}
                                />
                            </span>
                            <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <DateTimePicker
                                    label="End Date"
                                    views={['month', 'year', 'day']}
                                    openTo="day"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    sx={{ margin: '10px' }}
                                />
                            </span>
                        </div>
                    </LocalizationProvider>
                    <Button variant="contained" sx={{ mt: "10px", mb: 2, bgcolor: "success.main", ml: "auto", mr: 3 }} onClick={exportData}>Execute Marathon</Button>
                </Box>
                {loading && <LinearProgress />}
                <MUIDataTable
                    options={options}
                    columns={columns}
                    data={orderData}
                />
                <AreYouSure
                    style={{
                        backgroundColor: "background.default",
                        margin: "auto",
                        marginTop: "10vh",
                        padding: "20px",
                        width: "30vw",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        borderRadius: "5px",
                    }}
                    open={isModalOpen}
                    message={"Are you Sure Do you want to send the Order to Ninja?"}
                    handleClose={() => nullifyState()}
                    yes={() => {
                        submitAreYouSure(orderId);
                        nullifyState();
                    }}
                    no={() => nullifyState()}
                />
            </Box>
            <MarathonTable
                open={open}
                handleClose={handleClose}
                runMarathon={runMarathonAction}
                data={data}
                columns={modalColumns}
                options={{ selectableRows: 'none', print: false }}
            />
        </>
    );
};

export default InfluxerVendor;
