import React, { useState, useEffect } from "react";
import { Drawer, IconButton, Paper, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import Change from "./Change";
const removeTrailingSlash = (s) => {
  const a = s.replace(/\/$/, "")
  return a
}


const ChangeLogDrawer = ({ isOpen, close, drawerWidth }) => {

  const [changelog, setChangelog] = useState([])

  useEffect(() => {
    let data = JSON.stringify({
      "identity": process.env.REACT_APP_POCKETBASE_USERNAME,
      "password": process.env.REACT_APP_POCKETBASE_PASSWORD
    });
    let auth_config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://markfuller.dev/api/admins/auth-with-password',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.post(auth_config.url, data, auth_config)
      .then((response) => {
        const authToken = response.data.token
        getCollectionInfo(authToken)
      })
      .catch((error) => {
        console.log(error);
      });

  }, [])


  const getCollectionInfo = (authToken) => {
    console.log(authToken)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://markfuller.dev/api/collections/changelogs/records?sort=-created',
      headers: {
        'Authorization': authToken
      }
    };

    axios.get(config.url, config)
      .then((response) => {
        setChangelog(response.data.items)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Drawer
      style={drawerWidth}
      variant="persistent"
      anchor={"right"}
      open={isOpen}
      onClose={close}
      zIndex={100000}
      PaperProps={{
        sx: { ...drawerWidth, overflowX: "hidden", zIndex: "9999" },
      }}    >
      <div style={drawerWidth}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <h2>
            Change Log
          </h2>
          <IconButton onClick={close}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        {changelog
          .filter(c => c.route.startsWith(removeTrailingSlash(window.location.pathname)))
          .map(c => <Change key={c.id} change={c} />)}
      </div>
    </Drawer >
  );
};

export default ChangeLogDrawer;
