import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  ListItem,
  Checkbox,
  Autocomplete,
  Grid,
} from "@mui/material";
import React from "react";

const style = {
  maxHeight: '80vh',
  overflowY: 'auto',
};

const EditObject = (props) => {
  const [alterations, setAlterations] = React.useState({});
  const setAltered = (field, value) => {
    setAlterations((prev) => {
      const updated = { ...prev };
      if (updated[field] !== value && value !== undefined) {
        updated[field] = value;
      } else {
        delete updated[field];
      }
      //console.log("finalized alterations", updated);
      return updated;
    });
  };

  const form = [];

  props.config.forEach((c) => {
    if (c.field === "confirmDeletion") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <>
            <Checkbox
              label={c.label}
              defaultChecked={props.confirmProductDeletion}
              onChange={() =>
                props.setConfirmProductDeletion(!props.confirmProductDeletion)
              }
            />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                color: "#f44336",
              }}
            >
              {c.label}
            </span>
          </>
        ),
      });
    } else if (c.type === "checkbox") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <>
            <Checkbox
              label={c.label}
              defaultChecked={props.object[c.field]}
              disabled={!c.can}
              onChange={() => setAltered(c.field, !props.object[c.field])}
            />
            <span>{c.label}</span>
          </>
        ),
      });
    } else if (c.type === "text") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <TextField
            sx={{ width: "100%", ...c.style }}
            label={c.label}
            disabled={!c.can}
            defaultValue={props.object[c.field]}
            onChange={(e) => setAltered(c.field, e.target.value)}
          />
        ),
      });
    } else if (c.type === "select") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <Autocomplete
            fullWidth
            sx={{ maxWidth: "100%", width: "100%", ...c.style }}
            options={c.selectOptions}
            value={
              alterations[c.field]
                ? alterations[c.field]
                : props.object[c.field]
            }
            onChange={(e, v) => setAltered(c.field, v)}
            disabled={!c.can}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option, state) => (
              <ListItem
                {...props}
                key={option}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid white",
                }}
              >
                <div>{option}</div>
              </ListItem>
            )}
          />
        ),
      });
    }
  });

  return (
    <>
      {props.open && (
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container sx={props.style}>
              <Grid item lg={12} xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Athlete
                </Typography>
              </Grid>
              {form.map((f) => (
                <Grid
                  item
                  key={f.key}
                  style={{ paddingBottom: "10px" }}
                  {...f.style}
                >
                  {f.component}
                </Grid>
              ))}
              <Grid
                item
                lg={12}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <span>
                  <Button
                    sx={{
                      margin: "10px",
                      backgroundColor: "success.main",
                      color: "White",
                    }}
                    onClick={() => {
                      props.save(alterations);
                      setAlterations({});
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </span>
                <span>
                  <Button
                    sx={{
                      margin: "10px",
                      backgroundColor: "error.main",
                      color: "White",
                    }}
                    onClick={props.cancel}
                  >
                    Cancel
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditObject;
